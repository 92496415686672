import * as React from "react";

import "../styles/about.scss";

import Herve from "../assets/images/logo-herve.svg";
//import Team from "../assets/images/team.webp";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="about__container">
        {/* <div
          className="container__col container__col-3"
          style={{ backgroundImage: `url(${Team})` }}
        ></div> */}
        <div className="container__col about__content">
          <header>
            <h2>Qui sommes-nous ?</h2>
          </header>
          <article>
            <p>
              Créée en 1994, la SARL Huet & Haie, implantée à Vair-Sur-Loire,
              près d’Ancenis, à mi-chemin entre Nantes et Angers, met son
              savoir-faire et ses compétences à disposition pour la réalisation
              de tout type de projets : terrassement, voirie, élagage ou encore
              assainissement.
            </p>
            <p>
              Filiale du Groupe HERVE depuis 2019, Huet & Haie propose aux
              particuliers, professionnels et collectivités, des prestations de
              travaux publics sur-mesure avec un haut niveau de finition.
            </p>
            <p>
              Son expérience étant gage de son sérieux et de sa réactivité,
              l’entreprise Huet & Haie est à votre entière disposition pour tous
              renseignements, étude de votre projet ou pour des conseils
              personnalisés.
            </p>
              <a className="about__group" href="https://www.hervegroupe.fr/">
                <span>Découvrir le groupe</span>
                <img className="about__group__img" src={Herve} alt="Hervé" width="100" height="64" />
              </a>     
          </article>
        </div>
      </div>
    </section>
  );
};

export default About;
