import * as React from "react";

import "../styles/hoverCard.scss";
import ArrowRight from "../assets/icons/arrow-long-right.svg"

const HoverCard = ({ name, image, color, text }) => {
  return (
    <article className="hover-card" style={{ backgroundImage: `url(${image})` }}>
      <div className="hover-card__container">
        <div className={`hover-card__title hover-card__title-${color}`}>{name} <img src={ArrowRight} width="20" alt="En savoir plus" /></div>
        <div className="hover-card__content" dangerouslySetInnerHTML={{__html: text}} />
      </div>
    </article>
  );
};

export default HoverCard;
