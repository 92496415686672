import React, { useEffect } from "react";
import { FreeMode, Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PhotoSwipeLightbox from "photoswipe/lightbox";

import "../styles/work.scss";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";

import "photoswipe/style.css";

import AsphaltImg from "../assets/images/asphalt.webp";

import WorkImg1 from "../assets/images/work-1.webp";
import WorkImg2 from "../assets/images/work-2.webp";
import WorkImg3 from "../assets/images/work-3.webp";
import WorkImg4 from "../assets/images/work-4.webp";
import WorkImg5 from "../assets/images/work-5.webp";
import WorkImg6 from "../assets/images/work-6.webp";

const Work = () => {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#my-gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  return (
    <section
      id="work"
      className="work"
      style={{ backgroundImage: `url(${AsphaltImg})` }}
    >
      <div className="container work__container">
        <header>
          <h2>Nos Réalisations</h2>
        </header>
        <div class="work__prev"></div>
        <Swiper
          id="my-gallery"
          modules={[FreeMode, Navigation, Pagination, Autoplay]}
          className="work__swiper"
          slidesPerView={1}
          freeMode={true}
          autoplay={true}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".work__next",
            prevEl: ".work__prev",
            clickable: true,
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 50,
              pagination: false,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
              pagination: false,
            },
          }}
        >
          <SwiperSlide style={{ backgroundImage: `url(${WorkImg1})` }}>
            <a
              href={WorkImg1}
              data-pswp-width="800"
              data-pswp-height="450"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;
            </a>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${WorkImg2})` }}>
          <a
              href={WorkImg2}
              data-pswp-width="800"
              data-pswp-height="535"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;
            </a>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${WorkImg3})` }}>
          <a
              href={WorkImg3}
              data-pswp-width="535"
              data-pswp-height="800"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;
            </a>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${WorkImg4})` }}>
          <a
              href={WorkImg4}
              data-pswp-width="535"
              data-pswp-height="800"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;
            </a>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${WorkImg5})` }}>
          <a
              href={WorkImg5}
              data-pswp-width="800"
              data-pswp-height="450"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;
            </a>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${WorkImg6})` }}>
          <a
              href={WorkImg6}
              data-pswp-width="800"
              data-pswp-height="450"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;
            </a>
          </SwiperSlide>
        </Swiper>
        <div class="work__next"></div>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default Work;
