import * as React from "react";
import handleViewport from "react-in-viewport";

import "../styles/process.scss";
import ProcessStep from "./ProcessStep";

import ProcessIcon1 from "../assets/icons/process-step-1.svg";
import ProcessIcon2 from "../assets/icons/process-step-2.svg";
import ProcessIcon3 from "../assets/icons/process-step-3.svg";
import ProcessIcon4 from "../assets/icons/process-step-4.svg";
import ProcessIcon5 from "../assets/icons/process-step-5.svg";

const ProcessSteps = [
  {
    name: "Définition du projet",
    icon: ProcessIcon1,
    color: "color1",
    text: "À l’écoute de vos attentes, l’entreprise Huet & Haie prend le temps de définir avec vous l’ensemble des caractéristiques de votre projet.",
  },
  {
    name: "Conseils",
    icon: ProcessIcon2,
    color: "color2",
    text: "Forte d’un savoir-faire depuis près de 30 ans, l’entreprise Huet & Haie saura vous guider à travers des conseils avisés et répondre à toutes vos interrogations.",
  },
  {
    name: "Suivi",
    icon: ProcessIcon3,
    color: "color3",
    text: "Grâce à un suivi personnalisé, les équipes mettent en œuvre tous les moyens et techniques spécifiques à vos besoins.",
  },
  {
    name: "Réalisation",
    icon: ProcessIcon4,
    color: "color4",
    text: "Après l’étape de conception, les équipes gèrent la mise en place du chantier de A à Z avec un suivi pointilleux et régulier.",
  },
  {
    name: "Finition",
    icon: ProcessIcon5,
    color: "color5",
    text: "Afin de garantir un résultat optimal, l’entreprise Huet&Haie mise sur son sens du détail pour vous satisfaire au mieux.",
  }
];

const ProcessContainer = ({ inViewport, forwardedRef }) => {
  const ContainerActive = inViewport ? 'process__container-active' : '';
  return (
    <section id="process" className="process" ref={forwardedRef}>
      <header><h2>Notre Accompagnement</h2></header>
      <div className={`container process__container ${ContainerActive}`}>
      {ProcessSteps.map((step, index) => (
              <ProcessStep
                key={index}
                name={step.name}
                icon={step.icon}
                color={step.color}
                text={step.text}
              />
            ))}
      </div>
    </section>
  );
};

const Process = handleViewport(ProcessContainer);

export default Process;
