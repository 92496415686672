import * as React from "react";

import "../styles/activity.scss";

import HoverCard from "./HoverCard";

import ActivityImg1 from "../assets/images/activity-1.webp";
import ActivityImg2 from "../assets/images/activity-2.webp";
import ActivityImg3 from "../assets/images/activity-3.webp";
import ActivityImg4 from "../assets/images/activity-4.webp";

const Activities = [
  {
    name: "Terrassement",
    image: ActivityImg1,
    color: "color1",
    text: "Extraction, évacuation des eaux, décaissement, viabilisation, remblaiement, transport des déchets, <strong>Huet&nbsp;&&nbsp;Haie</strong> prépare votre terrain avant une construction, un aménagement ou une transformation de votre espace extérieur.",
  },
  {
    name: "Voirie",
    image: ActivityImg2,
    color: "color2",
    text: "Entièrement autonome pour les travaux de voirie, <strong>Huet&nbsp;&&nbsp;Haie</strong> possède tout le matériel et transport nécessaires pour réaliser votre chantier de A à Z : pose de pavés, bordures, empierrements, enrobés, etc. ",
  },
  {
    name: "Assainissement",
    image: ActivityImg3,
    color: "color3",
    text: "<strong>Huet&nbsp;&&nbsp;Haie</strong> réalise vos travaux d’assainissement (raccordement, tout à l’égout, micro-station, eaux usées et pluviales) par des dispositifs compatibles avec les exigences de la santé publique et de l’environnement.",
  },
  {
    name: "Élagage",
    image: ActivityImg4,
    color: "color4",
    text: "Tronçonneuses, débroussailleuses, broyeurs d’accotement ou encore épareuses, <strong>Huet&nbsp;&&nbsp;Haie</strong> possède du matériel de pointe pour assurer un élagage professionnel de qualité.",
  }
];

const Activity = () => {
  return (
    <section id="activity" className="activity">
      <header><h2>Nos Activités</h2></header>
      <div className="container activity__container">
      {Activities.map((activity, index) => (
              <HoverCard
                key={index}
                name={activity.name}
                image={activity.image}
                color={activity.color}
                text={activity.text}
              />
            ))}
      </div>
    </section>
  );
};

export default Activity;
