import * as React from "react";
import { Helmet } from "react-helmet";

import Header from "../components/Header";
import Head from "../components/Head";
import Footer from "../components/Footer";
import Intro from "../components/Intro";
import Activity from "../components/Activity";
import Work from "../components/Work";
import Process from "../components/Process";
import About from "../components/About";
import Contact from "../components/Contact";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Huet & Haie | Terrassement, Voirie, Assainissement et Élagage
        </title>
        <meta
          name="description"
          content="Huet & Haie, situé à Vair-sur-Loire, près d’Ancenis, vous accompagne dans vos travaux d’aménagements et de restructuration de vos extérieurs, pour tous types de maisons individuelles ou d’habitats collectifs."
        />
        <link rel="canonical" href="https://huetethaie.fr/" />
      </Helmet>
      <Head />
      <Header />
      <main id="main" className="site-main" role="main">
        <Intro />
        <Activity />
        <Work />
        <Process />
        <About />
        <Contact />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
