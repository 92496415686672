import * as React from "react";
import handleViewport from "react-in-viewport";

const ProcessStepComponent = ({ name, icon, color, text, inViewport, forwardedRef }) => {
  const StepActive = inViewport ? 'process-step-active' : '';
  return (
    <article className={`process-step ${StepActive} process-step-${color}`} ref={forwardedRef}>
      <div className="process-step__placeholder"></div>
      <div className="process-step__icon-holder">
        <div className="process-step__icon">
          <img src={icon} alt={name} />
        </div>
        <div className="process-step__icon-line">
          <div className="process-step__icon-line-inner"></div>
        </div>
      </div>
      <div className="process-step__placeholder">
        <div className="process-step__title">
          <h3>{name}</h3>
        </div>
        <div className="process-step__content">{text}</div>
      </div>
    </article>
  );
};

const ProcessStep = handleViewport(ProcessStepComponent /** options: {}, config: {} **/);

export default ProcessStep;
