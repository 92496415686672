import * as React from "react";

import "../styles/intro.scss";

import handleViewport from "react-in-viewport";

import HuetNHaie from "../assets/images/logo-name.svg";
import IntroImg1 from "../assets/images/intro-1.webp";
import IntroImg2 from "../assets/images/intro-2.webp";
import IntroImg3 from "../assets/images/intro-3.webp";

const IntroComponent = ({ inViewport, forwardedRef }) => {
  const IsVisible = inViewport ? 'container__col-active' : '';
  return (
    <section id="intro" className="intro" ref={forwardedRef}>
      <div className="container intro__container">
        <div className={`container__col ${IsVisible} container__col-2`}>
          <img className="intro__img" src={IntroImg1} alt="Terrassement" width="380" height="195" />
          <img className="intro__img" src={IntroImg2} alt="Élagage" width="160" height="110" />
          <img className="intro__img" src={IntroImg3} alt="Voirie" width="220" height="206" />
        </div>
        <div className="container__col container__col-2 intro__content">
          <header>
            <span>Bienvenue sur le site de</span>
            <img className="hh" src={HuetNHaie} alt="Huet & Haie" />
            <h1>Terrassement - Voirie - Assainissement - Élagage</h1>
          </header>
          <article>
            <p>
              Huet & Haie, situé à Vair-sur-Loire, près d’Ancenis, vous
              accompagne dans vos travaux d’aménagements et de restructuration
              de vos extérieurs, pour tous types de maisons individuelles ou
              d’habitats collectifs.
            </p>
            <p>
              La Société Huet & Haie, historiquement reconnue pour son expertise
              en matière de travaux publics et de matériels agricoles, a vu son
              activité évoluer et se diversifier vers une offre de prestations
              complémentaires : terrassement, voirie, assainissement ou encore
              élagage.
            </p>
            <p>
              Notre savoir-faire technique en travaux publics et en
              implantations paysagères et architecturales, associé à un
              véritable sens de l’écoute, nous permettent aujourd’hui de vous
              proposer une offre complète et un accompagnement personnalisé.
            </p>
          </article>
          <footer>particuliers <span>|</span> professionnels <span>|</span> collectivités</footer>
        </div>
      </div>
    </section>
  );
};

const Intro = handleViewport(IntroComponent);

export default Intro;
