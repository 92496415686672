import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import "../styles/contact.scss";
import MapIcon from "../assets/icons/location.svg";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/bf7bd480-7c68-11ed-b38f-a1ed22f366b1";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container contact__container">
        <div className="container__col container__col-2">
          <header>
            <h2>Nous contacter</h2>
          </header>
          <p>
            Huet & Haie, situé à Vair-sur-Loire, près d’Ancenis, intervient sur
            la Région des Pays de la Loire. À mi-chemin entre Nantes et Angers,
            sa situation géographique est un véritable atout qui lui permet de
            proposer un service de proximité et de qualité pour l’aménagement
            extérieur des professionnels, particuliers et collectivités.
          </p>
          <div className="contact__location">
            <div className="contact__location-icon">
              <img src={MapIcon} alt="localiser Huet & Haie" width="70" height="70" />
            </div>
            <div className="contact__location-content">
            <strong>HUET & HAIE</strong>
            La Foucherie - 44150 VAIR-SUR-LOIRE<br />
            <AnchorLink to="tel:+33240983948">02 40 98 39 48</AnchorLink>
            </div>
          </div>
          <form
            action={FORM_ENDPOINT}
            method="post"
            target="_self"
          >
            <div className="contact__field contact__field-half">
              <label htmlFor="name">Nom *</label>
              <input name="Lastname" id="lastname" placeholder="Nom *" type="text" required />
            </div>
            <div className="contact__field contact__field-half">
              <label htmlFor="email">Prénom *</label>
              <input name="Firstname" id="firstname" placeholder="Prénom *" type="text" required />
            </div>
            <div className="contact__field contact__field-half">
              <label htmlFor="email">Email *</label>
              <input name="Email" id="email" placeholder="Email *" type="email" required />
            </div>
            <div className="contact__field contact__field-half">
              <label htmlFor="email">Téléphone</label>
              <input name="Phone" id="phone" placeholder="Téléphone" type="text" />
            </div>
            <p>En cas de demande de devis, nous vous remercions de bien vouloir renseigner votre adresse postale :</p>
            <div className="contact__field contact__field-full">
              <label htmlFor="address">Adresse</label>
              <input name="address" id="address" placeholder="Adresse" type="text" />
            </div>
            <div className="contact__field contact__field-half">
              <label htmlFor="zip">Code postal</label>
              <input name="Zip" id="zip" placeholder="Code postal" type="number" />
            </div>
            <div className="contact__field contact__field-half">
              <label htmlFor="city">Ville</label>
              <input name="City" id="city" placeholder="Ville" type="text" />
            </div>
            <div className="contact__field contact__field-full">
              <label htmlFor="message">Message *</label>
              <textarea name="Message" id="message" placeholder="Message *" required />
            </div>
            <div className="contact__field contact__field-full contact__field-acceptance">
              <input name="Consent" id="consent" type="checkbox" required />
              <label htmlFor="Consent">
                En cochant cette case et en soumettant ce formulaire, j’accepte
                que mes données personnelles soient utilisées pour me
                recontacter dans le cadre de ma demande indiquée dans ce
                formulaire. Aucun autre traitement ne sera effectué avec mes
                informations. Pour connaitre et exercer vos droits, notamment de
                retrait de votre consentement à l’utilisation de données
                collectés par ce formulaire, veuillez consulter notre{" "}
                <AnchorLink to="/politique-de-confidentialite/#privacy">
                  politique de confidentialité
                </AnchorLink>
                .
              </label>
            </div>
            <div className="contact__field contact__field-full">
              <input type="submit" value="Envoyer" />
            </div>
          </form>
        </div>
        <div className="container__col container__col-2">
        <iframe className="contact__map" title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2700.7219700038804!2d-1.0706670843232977!3d47.397857379171334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48061e8271d428a7%3A0xc37e77b72226944f!2sLa%20Foucherie%2C%2044150!5e0!3m2!1sfr!2sfr!4v1652012897298!5m2!1sfr!2sfr" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
